import {useEffect, Suspense, lazy, Fragment, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

import {googleRecaptcheyKey} from "./config/Config";
import axios from "./config/Axios";
import {routes} from "./config/Routes";
import {isMobileDevice} from "./helpers/MobileDesignDetect";
import {initFbPixel} from './helpers/FbPixel';
import {initTikTokPixel} from './helpers/TikTokPixel';
import {checkLoggedUserInfo, authActions} from "./store/auth";
import Layout from './containers/Layout/Layout';
import Modal from "./components/UI/Modal/Modal";
import AppLoader from "./containers/AppLoader";
import Meta from "./containers/Meta";
import GoogleAnalytics from "./containers/GoogleAnalytics";
import ScrollToTop from "./containers/ScrollToTop";
import AppSocket from "./containers/AppSocket";
//import WebNotificationsCheck from "./containers/WebNotificationsCheck";
import {isTravelGirls} from "./config/Flavor";
import useUserCheckForModals from "./hooks/useUserCheckForModals";
import useHotjar from "./hooks/useHotjar";
import useFcmTokenCheck from "./hooks/useFcmCheck";
import AllowPushModal from "./containers/AllowPushModal/AllowPushModal";
const RegistrationValidation = lazy(() => import("./containers/Pages/RegistrationValidation/RegistrationValidation"));
const DesignSwitch = lazy(() => import("./containers/DesignSwitch"));
const Autologin = lazy(() => import("./containers/Pages/Autologin/Autologin"));
const EmailConfirm = lazy(() => import("./containers/Pages/EmailConfirm/EmailConfirm"));
const ValidateCheck = lazy(() => import("./containers/Pages/ValidateCheck/ValidateCheck"));
const Referral = lazy(() => import("./containers/Pages/Referral/Referral"));
const Unsubscribe = lazy(() => import('./containers/Pages/Unsubscribe/Unsubscribe'));
const Onboarding = lazy(() => import('./containers/Pages/Onboarding/Onboarding'));

const App = () => {
    useFcmTokenCheck();

    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const isFetched = useSelector(state => state.auth.fetchOnInit);
    const isInitiated = useRef(false);

    useEffect(() => {
        if (isFetched) {
            axios.post('config/stats', {ref: document.referrer, landing: (location.pathname+location.search)});
            initFbPixel(user);
            initTikTokPixel();
        }
    }, [isFetched]);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development' && isFetched) {
            Sentry.setUser({id: user ? user.id.toString() : null});
        }
    }, [user, isFetched])

    useEffect(() => {
        if (!isInitiated.current) {
            isInitiated.current = true;
            dispatch(authActions.setMobile({isMobile: isMobileDevice()}));
            dispatch(checkLoggedUserInfo());
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    useUserCheckForModals();
    useHotjar();

    if (!isFetched) {
        return <AppLoader />
    }

    return (
        <Fragment>
            <Meta />
            <GoogleAnalytics />
            <ScrollToTop />
            {/* Disabled on 2024-05-15 by AK REQUEST */}
            {/*{user && <WebNotificationsCheck userId={user.id} />}*/}
            {user && <AppSocket userId={user.id} rtmToken={user.additional.rtmToken} />}
            {!user ? (
                <GoogleReCaptchaProvider reCaptchaKey={googleRecaptcheyKey}>
                    <RouteList />
                </GoogleReCaptchaProvider>
            ) : <RouteList />}
        </Fragment>
    );
}

const LayoutRoutes = ({ component: Component, ...rest }) => {
    return (
        <Layout {...rest}>
            <rest.view />
        </Layout>
    );
}

const RouteList = () => (
    <Suspense fallback={<AppLoader />}>
        <Routes>
            {/*Links of old design*/}
            <Route path={'/page/terms-and-conditions'} element={<Navigate to={routes.termsAndConditions.path} replace={true} />} />
            <Route path={'/page/privacy-policy'} element={<Navigate to={routes.privacyPolicy.path} replace={true} />} />
            <Route path={'/page/about-us'} element={<Navigate to={routes.aboutTravelgirls.path} replace={true} />} />
            <Route path={'/page/travel-tips'} element={<Navigate to={routes.travelTips.path} replace={true} />} />
            <Route path={'/tips'} element={<Navigate to={routes.travelTips.path} replace={true} />} />
            <Route path={'/messaging_new/*'} element={<Navigate to={'/conversations'} replace={true} />} />
            <Route path={'/trips/map'} element={<Navigate to={routes.trips.path} replace={true} />} />
            <Route path={'/trips/trip'} element={<Navigate to={routes.trips.path} replace={true} />} />
            <Route path={'/profile/profile_views'} element={<Navigate to={routes.visitors.path} replace={true} />} />
            <Route path={'/profile/upgrade/*'} element={<Navigate to={routes.membership.path} replace={true} />} />
            <Route path={'/profile/*'} element={<Navigate to={routes.edit.path} replace={true} />} />
            <Route path={'/referral'} element={<Navigate to={routes.landing.path} replace={true} />} />
            <Route path={'/sitemap'} element={<Navigate to={routes.landing.path} replace={true} />} />
            <Route path={'/preferences'} element={<Navigate to={routes.landing.path} replace={true} />} />
            {/*Links of old design*/}
            <Route path="/design-switch/" element={<DesignSwitch />} />
            <Route path="/alogin/*" element={<Autologin />} />
            <Route path="/email-confirm/*" element={<EmailConfirm />} />
            <Route path="/validate-check-al/*" element={<ValidateCheck />} />
            <Route path="/r/:ref" element={<Referral />} />
            <Route path="/unsubscribe/*" element={<Unsubscribe />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/profile/gallery/registration" exact element={<RegistrationValidation />} />

            <Route path={routes.landing.path} exact element={<LayoutRoutes {...routes.landing} />} />
            <Route path={routes.members.path} exact element={<LayoutRoutes {...routes.members} />} />
            <Route path={routes.members.path + '/:genderPath'} exact element={<LayoutRoutes {...routes.members} />} />
            <Route path={routes.member.path} exact element={<LayoutRoutes {...routes.member} />} />
            <Route path={routes.support.path} exact element={<LayoutRoutes {...routes.support} />} />
            <Route path={routes.passwordReset.path} exact element={<LayoutRoutes {...routes.passwordReset} />} />
            <Route path={routes.login.path} exact element={<LayoutRoutes {...routes.login} />} />
            <Route path={routes.logout.path} exact element={<LayoutRoutes {...routes.logout} />} />
            {isTravelGirls && <Route path={routes.escortCountries.path} exact element={<LayoutRoutes {...routes.escortCountries} />} />}
            {isTravelGirls && <Route path={routes.escortCities.path} exact element={<LayoutRoutes {...routes.escortCities} />} />}
            {isTravelGirls && <Route path={routes.escort.path} exact element={<LayoutRoutes {...routes.escort} />} />}
            {isTravelGirls && <Route path={routes.sugarDaddyCountries.path} exact element={<LayoutRoutes {...routes.sugarDaddyCountries} />} />}
            {isTravelGirls && <Route path={routes.sugarDaddyCities.path} exact element={<LayoutRoutes {...routes.sugarDaddyCities} />} />}
            {isTravelGirls && <Route path={routes.sugarDaddy.path} exact element={<LayoutRoutes {...routes.sugarDaddy} />} />}
            {isTravelGirls && <Route path={routes.sugarBaby.path} exact element={<LayoutRoutes {...routes.sugarBaby} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyNewYork.path} exact element={<LayoutRoutes {...routes.sugarBabyNewYork} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyLosAngeles.path} exact element={<LayoutRoutes {...routes.sugarBabyLosAngeles} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyChicago.path} exact element={<LayoutRoutes {...routes.sugarBabyChicago} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyAustin.path} exact element={<LayoutRoutes {...routes.sugarBabyAustin} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyMiami.path} exact element={<LayoutRoutes {...routes.sugarBabyMiami} />} />}
            {isTravelGirls && <Route path={routes.sugarBabySeattle.path} exact element={<LayoutRoutes {...routes.sugarBabySeattle} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyDenver.path} exact element={<LayoutRoutes {...routes.sugarBabyDenver} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyNashville.path} exact element={<LayoutRoutes {...routes.sugarBabyNashville} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyPortland.path} exact element={<LayoutRoutes {...routes.sugarBabyPortland} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyHouston.path} exact element={<LayoutRoutes {...routes.sugarBabyHouston} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyBoston.path} exact element={<LayoutRoutes {...routes.sugarBabyBoston} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyPhiladelphia.path} exact element={<LayoutRoutes {...routes.sugarBabyPhiladelphia} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyDallas.path} exact element={<LayoutRoutes {...routes.sugarBabyDallas} />} />}
            {isTravelGirls && <Route path={routes.sugarBabyUs.path+'/*'} exact element={<LayoutRoutes {...routes.sugarBabyUs} />} />}
            <Route path={routes.trips.path} exact element={<LayoutRoutes {...routes.trips} />} />
            <Route path={routes.conversations.path+'/*'} element={<LayoutRoutes {...routes.conversations} />} />
            {isTravelGirls && <Route path={routes.photoRequests.path+'/*'} element={<LayoutRoutes {...routes.photoRequests} />} />}
            <Route path={routes.favorites.path} exact element={<LayoutRoutes {...routes.favorites} />} />
            <Route path={routes.visitors.path} exact element={<LayoutRoutes {...routes.visitors} />} />
            <Route path={routes.myTrips.path} exact element={<LayoutRoutes {...routes.myTrips} />} />
            <Route path={routes.edit.path} exact element={<LayoutRoutes {...routes.edit} />} />
            <Route path={routes.editTab.path} exact element={<LayoutRoutes {...routes.editTab} />} />
            <Route path={routes.myProfile.path} exact element={<LayoutRoutes {...routes.myProfile} />} />
            <Route path={routes.membership.path} exact element={<LayoutRoutes {...routes.membership} />} />
            <Route path={routes.membershipPayments.path} exact element={<LayoutRoutes {...routes.membershipPayments} />} />
            <Route path={routes.membershipCancel.path} exact element={<LayoutRoutes {...routes.membershipCancel} />} />
            {isTravelGirls && <Route path={routes.membershipPlan.path} exact element={<LayoutRoutes {...routes.membershipPlan} />} />}
            <Route path={routes.suspended.path} exact element={<LayoutRoutes {...routes.suspended} />} />
            <Route path={routes.verification.path+'/*'} exact element={<LayoutRoutes {...routes.verification} />} />
            <Route path={routes.verificationCamera.path+'/*'} exact element={<LayoutRoutes {...routes.verificationCamera} />} />
            <Route path={routes.groupMessage.path} exact element={<LayoutRoutes {...routes.groupMessage} />} />
            <Route path={routes.reportUser.path} exact element={<LayoutRoutes {...routes.reportUser} />} />
            <Route path={routes.reportComplaint.path} exact element={<LayoutRoutes {...routes.reportComplaint} />} />
            <Route path={routes.unwantedCountries.path} exact element={<LayoutRoutes {...routes.unwantedCountries} />} />
            <Route path={routes.unwantedUsers.path} exact element={<LayoutRoutes {...routes.unwantedUsers} />} />
            <Route path={routes.aboutTravelgirls.path} exact element={<LayoutRoutes {...routes.aboutTravelgirls} />} />
            <Route path={routes.homeScreen.path} exact element={<LayoutRoutes {...routes.homeScreen} />} />
            <Route path={routes.termsAndConditions.path} exact element={<LayoutRoutes {...routes.termsAndConditions} />} />
            <Route path={routes.privacyPolicy.path} exact element={<LayoutRoutes {...routes.privacyPolicy} />} />
            {isTravelGirls && <Route path={routes.faq.path} exact element={<LayoutRoutes {...routes.faq} />} />}
            <Route path={routes.travelTips.path} exact element={<LayoutRoutes {...routes.travelTips} />} />
            {isTravelGirls && <Route path={routes.travelgirlsApp.path} exact element={<LayoutRoutes {...routes.travelgirlsApp} />} />}
            {!isTravelGirls && <Route path={routes.yachts.path} element={<routes.yachts.view version={1} />} />}
            {!isTravelGirls && <Route path={routes.yachts.path+'2'} element={<routes.yachts.view version={2} />} />}
            {!isTravelGirls && <Route path={routes.yachts.path+'3'} element={<routes.yachts.view version={3} />} />}
            {!isTravelGirls && <Route path={routes.yachts.path+'4'} element={<routes.yachts.view />} version={4} />}
            {!isTravelGirls && <Route path={routes.dubai.path} exact element={<routes.dubai.view />} />}
            {!isTravelGirls && <Route path={routes.landingGay.path} exact element={<routes.landingGay.view />} />}
            <Route path={routes.notFound.path} element={<LayoutRoutes {...routes.notFound}/>} />

            /* T&C and Pp for App (just view) */
            <Route path={'/app'+routes.termsAndConditions.path} exact element={<routes.termsAndConditions.view />} />
            <Route path={'/app'+routes.privacyPolicy.path} exact element={<routes.privacyPolicy.view />} />
        </Routes>
        <Modal />
        <AllowPushModal />
    </Suspense>
);

export default App;
